<template>
  <page-layout title="Balises QUECLINK">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li class="is-active"><a href="#">Balises QUECLINK</a></li>
    </template>
    <list-queclink-trackers-action
      :loading="loading"
      :queclink-trackers="queclinkTrackers"
      @import="submit"
    />
    <o-message :errorMessages="importErrorMessages" title="Erreurs d'importation" />
    <list-queclink-trackers-filter />
    <list-queclink-trackers-table
      :queclinkTrackers="queclinkTrackers"
      :queclinkTrackerConfigs="queclinkTrackerConfigs"
      :loading="loading"
      class="box"
    />
  </page-layout>
</template>

<script>
import OMessage from '@components/Message.vue'
import PageLayout from '@components/PageLayout.vue'
import ListQueclinkTrackersTable from './ListQueclinkTrackersTable.vue'
import ListQueclinkTrackersFilter from './ListQueclinkTrackersFilter.vue'
import ListQueclinkTrackersAction from './ListQueclinkTrackersAction.vue'
import { getErrorMessages, importOwnerQueclinkTrackerError } from '@helpers/importErrorMessages.js'
import { mapActions } from 'vuex'

export default {
  name: 'ListQueclinkTrackersPage',

  components: {
    OMessage,
    PageLayout,
    ListQueclinkTrackersTable,
    ListQueclinkTrackersFilter,
    ListQueclinkTrackersAction,
  },

  data() {
    return {
      loading: false,
      queclinkTrackers: [],
      queclinkTrackerConfigs: [],
      importErrorMessages: [],
    }
  },

  async beforeRouteUpdate(to, from, next) {
    await this.fetch(to.query)
    next()
  },

  async mounted() {
    await this.fetch(this.$route.query)
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch(filters) {
      this.loading = true

      try {
        this.queclinkTrackers = await this.$services.queclinkTrackerService.getAll({
          keyword: filters?.keyword ?? null,
          availability: filters?.availability ?? null,
          owner: filters?.owner ?? null,
        })

        this.queclinkTrackerConfigs = this.queclinkTrackers.length
          ? await this.$services.queclinkTrackerConfigService.getAllByIds(
              this.queclinkTrackers.map((q) => q.id),
            )
          : []
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async submit({ result, error }) {
      if (error) {
        this.importErrorMessages = [error]
        return
      }

      this.importErrorMessages = []
      this.loading = true

      try {
        const updatedQueclinkTrackers = await this.$services.queclinkTrackerService.updateAll({
          queclinkTrackers: this.formatQueclinkTrackersData(result),
        })

        this.updateQueclinkTrackersList(updatedQueclinkTrackers)

        this.addToastMessage({
          text: 'Les propriétaires des balises Queclink ont été modifiés',
          type: 'is-success',
        })
      } catch (err) {
        this.importErrorMessages = getErrorMessages(err, importOwnerQueclinkTrackerError)
      }

      this.loading = false
    },

    formatQueclinkTrackersData(queclinkTrackers) {
      return queclinkTrackers.map((queclinkTracker) => ({
        ...queclinkTracker,
        owner: queclinkTracker.owner.toLowerCase(),
      }))
    },

    updateQueclinkTrackersList(updatedQueclinkTrackers) {
      this.queclinkTrackers = [
        ...updatedQueclinkTrackers,
        ...this.queclinkTrackers.filter((queclink) => {
          const ids = new Set(updatedQueclinkTrackers.map((s) => s.id))
          return !ids.has(queclink.id)
        }),
      ]
    },
  },
}
</script>
